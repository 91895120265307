import api from '../../api';
import { GET_TRANSACTIONS } from '../action-types/transaction';

export function getTransactions({startDate, endDate, page, itemsPerPage, currency, status, type, userId}) {
    return async dispatch => {
        try {
            const response = await api.get(`/api/ledger/transactions?userId=${userId}&startDate=${startDate}&endDate=${endDate}&page=${page}&itemsPerPage=${itemsPerPage}&currency=${currency}&status=${status}&type=${type}`);
            
            dispatch({
                type: GET_TRANSACTIONS,
                payload: response,
            });

            return true;
        } catch (error) {
            return false;
        }
    }
}
