import api from '../../api';
import { GET_ORDERS } from '../action-types/order';

export function getOrders({startDate, endDate, page, itemsPerPage, coin, status, userId}) {
    return async dispatch => {
        try {
            const response = await api.get(`/api/ledger/orders?userId=${userId}&startDate=${startDate}&endDate=${endDate}&page=${page}&itemsPerPage=${itemsPerPage}&coin=${coin}&status=${status}`);
            
            dispatch({
                type: GET_ORDERS,
                payload: response,
            });

            return true;
        } catch (error) {
            return false;
        }
    }
}
