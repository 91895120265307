import { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Table, Button, Modal } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import { getSettings, editSetting } from '../../store/actions/setting';
import SettingPopup from '../../components/settings/settingPopup';

const Settings = ({ getSettings, settings, createOrEdit }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [type, setType] = useState('Create');
    const [settingData, setSettingData] = useState({});
    useEffect(() => {
        getSettings();
    }, []);

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Value',
            key: 'value',
            dataIndex: 'value'
        },
        {
            title: 'Edit',
            key: 'edit',
            render: (_, setting) => <EditOutlined className='infoStyle' onClick={() => {
                                                                                    setShowPopup(true);
                                                                                    setSettingData(setting);
                                                                                    setType('Edit');
                                                                                }} />
        }
    ]

    const closePopup = () => {
        setShowPopup(false);
        setSettingData({});
    }

    return <>
            <Table dataSource={settings} columns={columns} pagination={false}></Table>
            <Button className="blockStyle" type='primary' onClick={() => {
                setShowPopup(true);
                setType('Create');
            }}>Create</Button>
            <Modal title={`${type} Setting`} visible={showPopup} footer={null} onCancel={closePopup} destroyOnClose={true}>
                <SettingPopup cancel={closePopup} createOrEdit={data => createOrEdit(data)} getSettings={getSettings}
                              settingData={settingData} type={type} />
            </Modal>            
           </>
}

function mapStateToProps(state) {
    return {
        settings: state.setting.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSettings: () => dispatch(getSettings()),
        createOrEdit: data => dispatch(editSetting(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);