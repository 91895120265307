import { useState } from 'react';
import { Input, Button, message } from 'antd';

const SettingPopup = props => {
    const { createOrEdit, settingData, type, cancel, getSettings } = props;
    const [name, setName] = useState(settingData.name || '');
    const [value, setValue] = useState(settingData.value || '');
    const [loading, setLoading] = useState(false);

    const handleAction = async() => {
        if (!name || !value) {
            return message.error('Please fill all the values');
        }
        setLoading(true)
        const res = await createOrEdit({ key: name, value });
        if (res === true) {
            cancel();
            getSettings();
            return message.success('Success');
        }
        setLoading(false);
        return message.error('Fail');
    }

    return (<div>
        <Input className="inputStyle" name="name" value={name} addonBefore='Name' onChange={e => setName(e.target.value)}></Input>
        <Input className="inputStyle" name="value" value={value} addonBefore='Value' onChange={e => setValue(e.target.value)}></Input>
        <Button className='buttonStyle' type="primary" onClick={handleAction} loading={loading}>{type}</Button>
    </div>)
}

export default SettingPopup;