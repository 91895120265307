import api from '../../api';
import { GET_ADJUSTMENTS } from '../action-types/adjustment';

export function getAdjustments({ page, itemsPerPage, keyword = '' }) {
    return async dispatch => {
        try {
            const response = await api.get(`/api/ledger/adjustments?page=${page}&itemsPerPage=${itemsPerPage}&keyword=${keyword}`);
            
            dispatch({
                type: GET_ADJUSTMENTS,
                payload: response,
            });

            return true;
        } catch (error) {
            return false;
        }
    }
}