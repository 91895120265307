import { BrowserRouter, Switch } from "react-router-dom";
import Login from './containers/login/login';
import Main from './containers/main/main';
import PrivateRoute from './routes/privateRoute';
import PublicRoute from './routes/publicRoute';

function App() {
  const privateRoutes = ['/', '/users', '/users/:id', '/withdraws', '/expenses', '/adjustments', '/settings', '/gift-cards'];
  return (
    <BrowserRouter>
       <Switch>
         {privateRoutes.map(route => <PrivateRoute path={route} component={Main} exact/>)}
        <PublicRoute path='/login'  component={Login} exact/>
       </Switch>
     </BrowserRouter>
  );  
}

export default App;