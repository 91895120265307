import api from '../../api';
import { GET_EXPENSES } from '../action-types/expense';

export function getExpenses() {
    return async dispatch => {
        try {
            const response = await api.get('/api/expense');

            dispatch({
                type: GET_EXPENSES,
                payload: {expenses: response.expenses, serviceBudget: response.serviceBudget}
            })
        } catch (error) {
            return false;
        }
    }
}

export function createExpense(data) {
    return async () => {
        try {
            await api.post('/api/expense', data);
            return true;
        } catch (error) {
            return false;
        }
    }
}

export function editExpense({ expense, description }, id) {
    return async () => {
        try {
            await api.put(`/api/expense/${id}`, { expense, description });
            return true;
        } catch (error) {
            return false;
        }
    }
}
