import { Button, Input, message } from "antd";
import { useState } from "react";

const { TextArea } = Input;

const CreateExpense = ({ create, cancel, getExpenses, serviceBudget }) => {
    const [expense, setExpense] = useState(0);
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState([
        {lang: 'English', placeholder: 'On what money is spent', value: ''},
        {lang: 'Russian', placeholder: 'На что тратятся деньги', value: ''},
        {lang: 'Armenian', placeholder: 'Ինչի վրա է ծախսվում', value: ''},
    ]);

    const createExpense = async () => {
        if (!expense || expense < 0) {
            return message.error('Please insert positive number for expense');
        }
        if (description.find(item => !item.value)) {
            return message.error('All languages should be filled');
        }
        if (expense > serviceBudget) {
            return message.error('Expense can not be more than service budget');
        }
        setLoading(true);
        const res = await create({expense, description: getDescription()});
        if (res === true) {
            cancel();
            getExpenses();
            return message.success('Expense successfully created');
        }
        setLoading(false);
        return message.error('Fail');      
    }

    const getDescription = () => {
        return {
            'en':  description.find(item => item.lang === 'English').value,
            'ru':  description.find(item => item.lang === 'Russian').value,
            'am':  description.find(item => item.lang === 'Armenian').value,
        }
    }

    return (
        <div>
            <div>
                <Input className="inputStyle" addonBefore="Amount spent: BTC" type="number" min="0" value={expense} onChange={e => setExpense(e.target.value)} />
            </div>
            {description.map((desc, index) => {
                return <div key={desc.lang}>
                        {desc.lang}
                        <TextArea className="inputStyle" placeholder={desc.placeholder} value={desc.value} 
                                  onChange={e => {
                                    const newDesc = [...description];
                                    newDesc[index].value = e.target.value;
                                    setDescription(newDesc);
                                  }} />
                    </div>
            })}
            <Button onClick={createExpense} type="primary" className="buttonStyle" loading={loading}>Create</Button>
            <Button onClick={cancel} className="buttonStyle">Cancel</Button>
        </div>
    )
}

export default CreateExpense;