import { useState } from 'react';
import { Input, Button, message, Switch } from 'antd';


const AdjustBalance = props => {
    const { currentUser, close, confirm } = props;
    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [shouldBeInSold, setShouldBeInSold] = useState(false);

    return  <div>
                <div>
                    <Input addonBefore="Amount" className="inputStyle" type="number" value={amount} 
                           onChange={e => {
                               if (Number(currentUser.balances['AE1']) + Number(e.target.value) < 0) {
                                   message.destroy();
                                   return message.error('Oops, the balance can not be negative');
                               }
                               setAmount(e.target.value);
                           }} />
                </div>
                <div>
                    <Input addonBefore="Description" className="inputStyle" value={description} 
                           onChange={e => setDescription(e.target.value)} />
                </div>
                Shoud be count in sold amount
                <div style={{'float': 'right'}}>
                    <Switch defaultChecked={shouldBeInSold} onChange={() => setShouldBeInSold(prevState => !prevState)} />
                </div>
                <br />
                {amount > 0 && <span><strong>Total will be</strong>: {Number(currentUser.balances['AE1']) + Number(amount)}</span>}
                <div className="blockStyle">
                    <Button type="danger" className="buttonStyle" onClick={close}>Cancel</Button>
                    <Button type="primary" className="buttonStyle" loading={loading}
                            onClick={async () => {
                                        setLoading(true);
                                        await confirm({ amount, userId: currentUser.id, description, shouldBeInSold });
                                        setLoading(false);
                                    }}>
                        Confirm
                    </Button>
                </div>                
            </div>
}

export default AdjustBalance;
