import { GET_WITHDRAWS } from '../action-types/withdraw';

const initialState = {
    withdraws: {items: []}
}

export default function withdrawReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_WITHDRAWS:
            return {
                ...state,
                withdraws: action.payload
            }
        default:
            return state
    }

}
