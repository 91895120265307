import { GET_USERS, GET_USER } from '../action-types/user';

const initialState = {
    users: {items: []},
    user: {properties: {}, balances: {}}
}

export default function userReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case GET_USER:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state
    }

}
