import { useEffect, useState } from 'react';
import { Table, Pagination } from "antd";
import { useParams } from 'react-router-dom';
import moment from "moment";

const UserTransactions = ({ getTransactions, transactions, transactionTotal }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setLoading(true);
        getTransactionData().then(() => setLoading(false));
    }, []);

    const getTransactionData = async (page=1, status='any', type='any',  currency='any', startDate=new Date('2020').getTime(), endDate=new Date('2024').getTime(), itemsPerPage=10, userId = id) => {
        return getTransactions({startDate, endDate, page, itemsPerPage, currency, status, type, userId});
    } 

    const onPageChange = async value => {
        setCurrentPage(value);
        setLoading(true);
        await getTransactionData(value);
        setLoading(false);
    }

    const columns = [
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type'
        },
        {
            title: 'Currency',
            key: 'currency',
            dataIndex: 'currency'
        },
        {
            title: 'Date',
            key: 'date',
            render: (_, transaction) => moment(transaction.created).format('MMMM Do YYYY hh:mm:ss a')
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount'
        },        
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        }
    ];

    return <>
             <Table dataSource={transactions} columns={columns} loading={loading} 
                    pagination={false}>
            </Table>
            <div className='paginationStyle'>
                    <Pagination onChange={onPageChange}
                            total={transactionTotal}
                            current={currentPage}
                            pageSize={pageSize} />
                </div>
          </>
}

export default UserTransactions;
