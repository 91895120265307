import { useState, useEffect } from 'react';
import { Table, Input, Pagination, Button, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { getUsers, adjustUserBalance } from '../../store/actions/user';
import AdjustBalance from "../../components/adjustBalance/adjustBalance";
import { useHistory, useLocation } from 'react-router-dom';

const { Search } = Input;

const Users = props => {
    const { users, getUsers, userCount, totalUsersCount, adjustUserBalance, buyersCount } = props;
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showBalacePopup, handleAdjustBalancePopup] = useState(false);
    const [currentUser, setCurrentUser] = useState({balances: {}});    
    const pageSize = 10;
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (!location.state) {
            setLoading(true);
            getUsers({ page: 1, itemsPerPage: pageSize }).then(() => setLoading(false));
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            setLoading(true);
            const { keyword, currentPage } = location.state;
            setKeyword(keyword);
            setCurrentPage(currentPage);
            getUsers({ page: currentPage, itemsPerPage: pageSize, keyword }).then(() => setLoading(false));
        }
    }, [location.state]);

    const onSearch = async value => {
        setKeyword(value);
        setCurrentPage(1);
        setLoading(true);
        await getUsers({ page: 1, itemsPerPage: pageSize, keyword: value });
        setLoading(false);
    }

    const onPageChange = async value => {
        setCurrentPage(value);
        setLoading(true);
        await getUsers({ page: value, itemsPerPage: pageSize, keyword });
        setLoading(false);
    }

    const columns = [
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email'
        },
        {
            title: 'Referral Count',
            key: 'referralCount',
            render: (_, user) => user.properties.referralCount || 0
        },
        {
            title: 'AE1 Balance',
            key: 'ae1Balance',
            render: (_, user) => user.balances['AE1']
        },
        {
            title: 'BTC Balance',
            key: 'btcBalance',
            render: (_, user) => user.balances['BTC']
        },
        {
            title: 'Adjust AE1 Balance',
            key: 'adjustBalance',
            render: (_, user) => <Button type="primary" className="buttonStyle"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAdjustBalancePopup(!showBalacePopup);
                                        setCurrentUser(user);
                                    }}>Adjust</Button>
        }
    ];

    const confirmAdjustBalance = async ({ amount, userId, description, shouldBeInSold }) => {
        const res = await adjustUserBalance({ amount, userId, description, shouldBeInSold });
        if (res === true) {
            setLoading(true);
            await getUsers({ page: currentPage, itemsPerPage: pageSize, keyword });
            setLoading(false);
            handleAdjustBalancePopup(false);
            return message.success('Balance successfully updated');
        }
        return message.error(res);
    }

    return  <div>
                <h2>Users - Total Count {totalUsersCount}, Distinct buyers count - {buyersCount}</h2>
                <Search placeholder="Email" allowClear onSearch={onSearch} style={{ width: 270 }} value={keyword} onChange={e => setKeyword(e.target.value)} />
                <Table dataSource={users} columns={columns} loading={loading} 
                       pagination={false} rowClassName="hoverRow"  onRow={(record) => {
                                                return {
                                                    onClick: () => history.push({ 
                                                        pathname: `/users/${record.id}`,
                                                        state: { keyword, currentPage }, 
                                                    })
                                                };
                                            }}></Table>
                <Modal 
                    title={<span>Adjust AE1 Balance, Current: {currentUser.balances['AE1']}, <br /> Email: {currentUser.email}</span>}
                    visible={showBalacePopup}
                    onCancel={() => handleAdjustBalancePopup(false)}
                    footer={null}
                    destroyOnClose={true}
                    >
                    <AdjustBalance currentUser={currentUser} close={() => handleAdjustBalancePopup(false)} 
                                   confirm={({ amount, userId, description, shouldBeInSold }) => confirmAdjustBalance({ amount, userId, description, shouldBeInSold })} />
                </Modal>   
                <div className='paginationStyle'>
                    <Pagination onChange={onPageChange}
                            total={userCount}
                            current={currentPage}
                            pageSize={pageSize} />
                </div>
            </div>
}

function mapStateToProps(state) {
    return {
        users: state.user.users.items,
        userCount: state.user.users.total,
        totalUsersCount: state.user.users.totalUsersCount,
        buyersCount: state.user.users.buyersCount
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUsers: data => dispatch(getUsers(data)),
        adjustUserBalance: data => dispatch(adjustUserBalance(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
