import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Table, Pagination, Input, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getAdjustments } from "../../store/actions/adjustment";
import moment from "moment";

const { Search } = Input;

const Adjustments = props => {
    const { getAdjustments, adjustments, adjustmentCount, 
            totalAdjustmentCount } = props;
    const [loading, setLoading] = useState(false); 
    const [keyword, setKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
    const [description, setDescription] = useState('');
    const pageSize = 10;

    useEffect(() => {
        setLoading(true);
        getAdjustments({ page: 1, itemsPerPage: pageSize }).then(() => setLoading(false));
    }, []);
    
    const onPageChange = async value => {
        setCurrentPage(value);
        setLoading(true);
        await getAdjustments({ page: value, itemsPerPage: pageSize, keyword });
        setLoading(false);
    }

    const onSearch = async value => {
        setKeyword(value);
        setCurrentPage(1);
        setLoading(true);
        await getAdjustments({ page: 1, itemsPerPage: pageSize, keyword: value });
        setLoading(false);
    }

    const columns = [,
        {
            title: 'Date',
            key: 'date',
            render: (_, record) => moment(record.created).format('MMMM Do YYYY hh:mm:ss a')
        },
        {
            title: 'User Email',
            key: 'email',
            render: (_, record) => record.user[0].email
        },
        {
            title: 'Price',
            key: 'price',
            render: (_, record) => `${record.extra.priceAlphaToken} USD`
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'paymentAmount'
        },        
        {
            title: 'Currency',
            key: 'currency',
            render: (_, record) => <>{record.paymentCurrency} {!!record.extra.description && <InfoCircleOutlined style={{marginLeft: '40px'}} className='infoStyle' onClick={() => {
                setShowDescriptionPopup(!showDescriptionPopup);
                setDescription(record.extra.description)
            }
          } />}</>
        }
    ];

    return <div>
       <h2>Adjustments - Total Count {totalAdjustmentCount}</h2>
       <Search placeholder="Email" allowClear onSearch={onSearch} style={{ width: 270 }} />
       <Table dataSource={adjustments} columns={columns} loading={loading} 
              pagination={false}>
        </Table>
        <div className='paginationStyle'>
            <Pagination onChange={onPageChange}
                total={adjustmentCount}
                current={currentPage}
                pageSize={pageSize} />
        </div>
        <Modal title="Adjustment Reason" visible={showDescriptionPopup} footer={null} onCancel={() => {
                                                                                            setShowDescriptionPopup(false);
                                                                                            setDescription('');
                                                                                        }} destroyOnClose={true}>
            <p>{description}</p>
        </Modal>       
    </div>
}

function mapStateToProps(state) {
    return {
        adjustments: state.adjustment.adjustments.items,
        adjustmentCount: state.adjustment.adjustments.total,
        totalAdjustmentCount: state.adjustment.adjustments.totalAdjustmentCount
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAdjustments: data => dispatch(getAdjustments(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Adjustments);