import { GET_TRANSACTIONS } from '../action-types/transaction';

const initialState = {
    transactions: {items: []}
}

export default function transactionReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload
            }       
        default:
            return state
    }

}
