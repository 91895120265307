import { useEffect, useState } from 'react';
import { Descriptions, Spin, Empty, Button, message, 
         Modal, Input } from "antd";
import { useParams } from 'react-router-dom';
import moment from "moment";
import classesLogin from '../../components/login/login.module.scss';

const UserInfo = ({ user, getUser, activateUser, disableUserTfa, userData }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [activationPopup, setActivationPopup] = useState(false);
    const [disableTfaPopup, setDisableTfaPopup] = useState(false);
    const [reason, setReason] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [tfaButtonLoading, setTfaButtonLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []); 
    
    const activate = async () => {
        setButtonLoading(true);
        const res = await activateUser({ id, reason });
        setButtonLoading(false);
        if (res === true) {
            getData();
            setActivationPopup(false);
            return message.success('User successfully activated');
        }
        return message.error(res);
    }

    const disableTfa = async () => {
        setTfaButtonLoading(true);
        const res = await disableUserTfa(id);
        setTfaButtonLoading(false);
        if (res === true) {
            getData();
            setDisableTfaPopup(false);
            message.success('TFa for the user successfully disabled');
            if (userData.id === id) {
                window.location.reload();
            }
            return;
        }
        return message.error(res);
    }

    const getData = () => {
        setLoading(true);
        getUser(id).then(() => setLoading(false));
    }

    return <>
             {loading ? <div className={classesLogin.centerBlock}><Spin /></div> : 
                    !user.email ? <div className={classesLogin.centerBlock}><Empty /></div> : 
                    <Descriptions title="User Account Info" layout="vertical" column={1} bordered>
                        <Descriptions.Item label={<><strong>Email</strong> - {user.email}</>}></Descriptions.Item>
                        <Descriptions.Item 
                         label={<><strong>Status</strong> - {user.status} 
                         {user.status === 'new' && <Button className='buttonStyle leftSpace' type='primary' onClick={() => setActivationPopup(true)}>Activate</Button>}</>}>
                        </Descriptions.Item>
                        <Descriptions.Item label={<><strong>Role</strong> - {user.role}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>Deposit address</strong> - {user.properties.depositWalletAddressAE1 || 'Not exists'}</>}>
                        </Descriptions.Item>                        
                        <Descriptions.Item label={<><strong>Balances</strong> - {`${user.balances['AE1']} AE1`}, {`${user.balances['BTC']} BTC`}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>BTC Earning</strong> - {user.earningBTC}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>Last Daily Income (BTC)</strong> - {user.lastDailyIncome}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>Referral Earning (AE1)</strong> - {user.referralEarning}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>Current referral token</strong> - {user.properties.referralTokens ? user.properties.referralTokens[user.properties.referralTokens.length-1] : `Does not have as ${user.status === 'new' ? 'not active user' : 'very old user'}`}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>People registered with referral token(s)</strong> - {user.properties.referralCount || 0}</>}></Descriptions.Item>
                        <Descriptions.Item label={<><strong>First Login</strong> - {String(user.firstLogin)}</>}></Descriptions.Item>
                        <Descriptions.Item 
                            label={<><strong>TFA enabled</strong> - {String(user.TFAEnabled)} 
                            {!!user.TFAEnabled && <Button className='buttonStyle leftSpace' type='primary' onClick={() => setDisableTfaPopup(true)}>Disable</Button>}</>}>
                        </Descriptions.Item> 
                        <Descriptions.Item label={<><strong>Account created</strong> - {moment(user.created).format('MMMM Do YYYY hh:mm:ss')}</>}></Descriptions.Item>                        
                    </Descriptions>}
                    <Modal title="User Activation" visible={activationPopup} footer={null} onCancel={() => setActivationPopup(false)} destroyOnClose={true}>
                        <div>
                            <div>Are you sure you want to activate this user?</div>
                            <br />
                            <div>
                                <Input className="inputStyle" addonBefore="Reason" type="text" value={reason} onChange={e => setReason(e.target.value)} />
                            </div>
                            <div className="blockStyle">
                                <Button type="primary" className="buttonStyle" onClick={activate} loading={buttonLoading}>Yes</Button>
                                <Button type="danger" className="buttonStyle" onClick={() => setActivationPopup(false)}>Cancel</Button>
                            </div>                        
                        </div>
                    </Modal>
                    <Modal title="Diable User TFA" visible={disableTfaPopup} footer={null} onCancel={() => setDisableTfaPopup(false)} destroyOnClose={true}>
                        <div>
                            <div>Are you sure you want to disable the tfa for this user?</div>
                            <div className="blockStyle">
                                <Button type="primary" className="buttonStyle" onClick={disableTfa} loading={tfaButtonLoading}>Yes</Button>
                                <Button type="danger" className="buttonStyle" onClick={() => setDisableTfaPopup(false)}>Cancel</Button>
                            </div>                        
                        </div>
                    </Modal>
          </>
}

export default UserInfo;
