import { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, 
         LogoutOutlined, MoneyCollectOutlined, SettingOutlined, 
         PayCircleOutlined, DollarOutlined, GiftFilled } from '@ant-design/icons';
import { logout } from '../../store/actions/auth';
import classes from './main.module.scss';
import { useHistory, Route, Switch } from 'react-router-dom';
import Users from '../users/users';
import Withdraws from '../withdraws/withdraws';
import Expense from '../expense/expense';
import UserDetails from '../users/userDetails';
import Adjustments from '../adjustments/adjustments';
import Settings from '../settings/settings';
import GiftCards from "../giftCards/giftCards";

const { Sider, Content, Header } = Layout;

const Main = props => {
    const { userData, logout } = props;
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const fullHeight = { minHeight: '90vh' };
    const history = useHistory();

    const logoutFunc = async () => {
        const res = await logout();
        if (res === true)  {
            history.push('/');
        }
    }

    const getActiveKey = () => {
        switch(history.location.pathname) {
            case '/users':
                return '1';
            case '/withdraws':
                return '2';
            case '/expenses':
                return '3';
            case '/adjustments':
                return '4';
            case '/settings':
                return '5';
            case '/gift-cards':
                return '6';
            default:
                return;
        }
    }

    return  <Layout style={fullHeight}>               
                <Sider width={200} collapsible collapsed={menuCollapsed} onCollapse={setMenuCollapsed}>
                    <div className={classes.logoContainer}>
                        <img className={classes.logo} alt="logo" src="/logo.svg" />
                    </div>
                    <Menu theme="dark" mode="inline" selectedKeys={[getActiveKey()]}>
                        <Menu.Item key="1" icon={<UserOutlined />} onClick={() => history.push('/users')}>Users</Menu.Item>
                        <Menu.Item key="2" icon={<PayCircleOutlined />} onClick={() => history.push('/withdraws')}>Withdraw requests</Menu.Item>
                        <Menu.Item key="3" icon={<MoneyCollectOutlined />} onClick={() => history.push('/expenses')}>Expenses</Menu.Item>
                        <Menu.Item key="4" icon={<DollarOutlined />} onClick={() => history.push('/adjustments')}>Adjustments</Menu.Item>
                        <Menu.Item key="5" icon={<SettingOutlined />} onClick={() => history.push('/settings')}>Settings</Menu.Item>
                        <Menu.Item key="6" icon={<GiftFilled />} onClick={() => history.push('/gift-cards')}>Gift Cards</Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Header>
                        <div onClick={() => setMenuCollapsed(!menuCollapsed)} className={classes.icon} style={{'float': 'left'}}>
                            {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </div>
                        <div className={classes.email}>
                            {userData.email} <span className={classes.icon} onClick={logoutFunc}><LogoutOutlined /></span>
                        </div>
                    </Header>
                    <Content style={fullHeight}>
                        <div className={classes.innerContent}>
                            <Switch>
                                <Route path="/users" component={Users} exact />
                                <Route path="/withdraws" component={Withdraws} />
                                <Route path="/expenses" component={Expense} />
                                <Route path="/users/:id" component={UserDetails} />
                                <Route path="/adjustments" component={Adjustments} />
                                <Route path="/settings" component={Settings} />
                                <Route path="/gift-cards" component={GiftCards} />
                            </Switch>
                        </div>
                    </Content>
                </Layout>
            </Layout>
}

function mapStateToProps(state) {
    return {
        userData: state.auth.userData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
