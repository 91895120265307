import { Tabs } from "antd";
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import UserInfo from "../../components/users/userInfo";
import UserTransactions from "../../components/users/userTransactions";
import UserOrders from "../../components/users/userOrders";
import { getUser, activateUser, disableUserTfa } from '../../store/actions/user';
import { getTransactions } from '../../store/actions/transaction';
import { getOrders } from '../../store/actions/order'
import { LeftOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const UserDetails = props => { 
    const { user, getUser, getTransactions, transactions, transactionTotal, getOrders,
            orders, orderTotal, activateUser, disableUserTfa, userData } = props;
    const location = useLocation();
    const history = useHistory();

    const { keyword, currentPage } = location.state;

    return <> 
             <span className="hoverRow" onClick={() => history.push({ 
                                                    pathname: '/users',
                                                    state: { keyword, currentPage }, 
                                                })}><LeftOutlined /> Back</span>
             <Tabs>
                <TabPane tab="Info" key="1">
                    <UserInfo user={user} getUser={getUser} activateUser={activateUser} 
                              disableUserTfa={disableUserTfa} userData={userData} />
                </TabPane>
                <TabPane tab="Transactions" key="2">
                    <UserTransactions getTransactions={getTransactions} transactions={transactions} 
                                      transactionTotal={transactionTotal} />
                </TabPane>
                <TabPane tab="Orders" key="3">
                    <UserOrders getOrders={getOrders} orders={orders} orderTotal={orderTotal} />
                </TabPane>
             </Tabs>
          </>
}

function mapStateToProps(state) {
    return {
        users: state.user.users.items,        
        user: state.user.user,
        transactions: state.transaction.transactions.items,
        transactionTotal: state.transaction.transactions.total,
        orders: state.order.orders.items,
        orderTotal: state.order.orders.total,
        userData: state.auth.userData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: id => dispatch(getUser(id)),
        getTransactions: data => dispatch(getTransactions(data)),
        getOrders: data => dispatch(getOrders(data)),
        activateUser: data => dispatch(activateUser(data)),
        disableUserTfa: id => dispatch(disableUserTfa(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
