import { useState, useEffect } from 'react';
import { Table, Input, Pagination, Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { getGiftCards } from '../../store/actions/giftCard';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from "moment";

const { Search } = Input;

const GiftCards = props => {
    const { giftCards, getGiftCards, giftCardCount, totalGiftCardCount } = props;
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showNotePopup, handleNotePopup] = useState(false);
    const [note, setNote] = useState('');
    const [isNew, setIsNew] = useState(false);
    const pageSize = 10;

    useEffect(() => {
        setLoading(true);
        getGiftCards({ page: 1, itemsPerPage: pageSize, keyword, isNew }).then(() => setLoading(false));
    }, [isNew]);

    const onSearch = async value => {
        setKeyword(value);
        setCurrentPage(1);
        setLoading(true);
        await getGiftCards({ page: 1, itemsPerPage: pageSize, keyword: value, isNew });
        setLoading(false);
    }

    const onPageChange = async value => {
        setCurrentPage(value);
        setLoading(true);
        await getGiftCards({ page: value, itemsPerPage: pageSize, keyword, isNew });
        setLoading(false);
    }

    let columns = [
        {
            title: 'Date',
            key: 'date',
            render: (_, record) => moment(record.created).format('MMMM Do YYYY hh:mm:ss a')
        },
        {
            title: 'Creator',
            key: 'creator',
            render: (_, record) => record.email[0]
        },
        {
            title: 'Redeemer',
            key: 'redeemer',
            render: (_, record) => !record.redeemer || !record.redeemer[0] ? 'No one' 
                                   : <div>{record.redeemer}  <div style={{'fontSize': '11px'}}>{moment(record.redeemDate).format('MMMM Do YYYY hh:mm:ss a')}</div></div>
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount'
        },
        {
            title: 'Promo Code',
            key: 'promoCode',
            dataIndex: 'promoCode'
        },
        {
            title: 'Price',
            key: 'price',
            render: (_, record) => `${record.price} USD`
        },
        {
            title: 'Order ID',
            key: 'orderID',
            dataIndex: 'orderID'
        },
        {
            title: 'Note',
            key: 'note',
            render: (_, record) => !record.note ? 'No note' : <InfoCircleOutlined className='infoStyle' onClick={() => {
                handleNotePopup(true);
                setNote(record.note);
            }} />
        }
    ];

    if (isNew) {
        columns = columns.filter(column => column.key !== 'redeemer');
    }

    return  <div>
                <h2>Gift Cards - Total Count {totalGiftCardCount}</h2>
                <Checkbox value={isNew} onChange={() => setIsNew(prevState => !prevState)}>Not Used</Checkbox>
                <br />
                <Search placeholder={`Email - creator ${!isNew ? 'or redeemer' : ''}`} allowClear onSearch={onSearch} style={{ width: 270 }} />
                <Table dataSource={giftCards} columns={columns} loading={loading} pagination={false} ></Table>
                <Modal 
                    title={'Gift card note'}
                    visible={showNotePopup}
                    onCancel={() => handleNotePopup(false)}
                    footer={null}
                    destroyOnClose={true}
                    >
                    {note}
                </Modal>   
                <div className='paginationStyle'>
                    <Pagination onChange={onPageChange}
                            total={giftCardCount}
                            current={currentPage}
                            pageSize={pageSize} />
                </div>
            </div>
}

function mapStateToProps(state) {
    return {
        giftCards: state.giftCard.giftCardData.giftCards,
        giftCardCount: state.giftCard.giftCardData.count,
        totalGiftCardCount: state.giftCard.giftCardData.totalGiftCardCount
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGiftCards: data => dispatch(getGiftCards(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
