import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getMe } from '../store/actions/auth';

const PrivateRoute = ({component: Component, ...rest}) => {    
    const { isLoggedIn, getMe } = rest;
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        getMe().then(() => {
            setLoaded(true);
        });
    }, []);

    if (loaded) {
        return (
            <Route {...rest} render={props => (
                isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
            )} />
        );
    }
    return <></>    
};

function mapStateToProps(state) {
    return {
        isLoggedIn: !!state.auth.userData.email
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMe: () => dispatch(getMe())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);