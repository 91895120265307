import api from '../../api';
import { GET_USERS, GET_USER } from '../action-types/user';

export function getUsers({ page, itemsPerPage, keyword }) {
    return async dispatch => {
        try {
            const response = await api.post('/api/user/search', { page, itemsPerPage, keyword });
            {
                const { totalUsersCount, buyersCount, searchResult: { items, itemsPerPage, page, total }} = response;

                dispatch({
                    type: GET_USERS,
                    payload: { items, itemsPerPage, page, total, totalUsersCount, buyersCount },
                });
            }
            

            return true;
        } catch (error) {
            return false;
        }
    }
}

export function getUser(id) {
    return async dispatch => {
        try {
            const response = await api.get(`/api/user/${id}`);

            dispatch({
                type: GET_USER,
                payload: response,
            });
            

            return true;
        } catch (error) {
            return false;
        }
    }
}

export function adjustUserBalance({ amount, userId, description, shouldBeInSold }) {
    return async dispatch => {
        try {
            await api.post('/api/user/adjust-balance', {  amount, userId, description, shouldBeInSold });

            return true;
        } catch (error) {
            return error.message;
        }
    }
}

export function activateUser({ id, reason }) {
    return async dispatch => {
        try {
            await api.patch(`/api/user/activate/${id}`, { explainMessage: reason });

            return true;
        } catch (error) {
            return error.message;
        }
    }
}

export function disableUserTfa(id) {
    return async dispatch => {
        try {
            await api.post(`/api/tfa/disable/${id}`);

            return true;
        } catch (error) {
            return error.message;
        }
    }
}