import {combineReducers} from 'redux'
import authReducer from './authReducer';
import userReducer from './userReducer';
import withdrawReducer from './withdrawReducer';
import expenseReducer from './expenseReducer'
import transactionReducer from './transactionReducer'
import orderReducer from './orderReducer';
import adjustmentReducer from './adjustmentReducer';
import settingReducer from './settingReducer';
import giftCardReducer from './giftCardReducer';

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    withdraw: withdrawReducer,
    expense: expenseReducer,
    transaction: transactionReducer,
    order: orderReducer,
    adjustment: adjustmentReducer,
    setting: settingReducer,
    giftCard: giftCardReducer
});