import { GET_SETTINGS } from '../action-types/setting';

const initialState = {
    settings: []
}

export default function settingReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }       
        default:
            return state
    }

}
