import { GET_EXPENSES } from '../action-types/expense';

const initialState = {
    expenses: [],
    serviceBudget: 0
}

export default function expenseReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_EXPENSES:
            return {
                ...state,
                expenses: action.payload.expenses,
                serviceBudget: action.payload.serviceBudget
            }
        default:
            return state
    }

}
