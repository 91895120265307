import { GET_GIFT_CARDS } from "../action-types/giftCard";

const initialState = {
    giftCardData: {giftCards: []}
}

export default function giftCardReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_GIFT_CARDS:
            return {
                ...state,
                giftCardData: action.payload
            }
        default:
            return state
    }

}
