import { GET_ORDERS } from '../action-types/order';

const initialState = {
    orders: {items: []}
}

export default function orderReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload
            }       
        default:
            return state
    }

}
