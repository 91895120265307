import { useEffect, useState } from 'react';
import { Table, Pagination } from "antd";
import { useParams } from 'react-router-dom';
import moment from "moment";

const UserOrders = ({ getOrders, orders, orderTotal }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setLoading(true);
        getOrderData().then(() => setLoading(false));
    }, []);

    const getOrderData = async (page=1, status='any', startDate=new Date('2020').getTime(), endDate=new Date('2024').getTime(), itemsPerPage=10, coin='any', userId = id) => {
        return getOrders({startDate, endDate, page, itemsPerPage, coin, status, userId});
    }

    const onPageChange = async value => {
        setCurrentPage(value);
        setLoading(true);
        await getOrderData(value);
        setLoading(false);
    }

    const columns = [,
        {
            title: 'Date',
            key: 'date',
            render: (_, order) => moment(order.created).format('MMMM Do YYYY hh:mm:ss a')
        },
        {
            title: 'Amount',
            key: 'amount',
            dataIndex: 'amount'
        },   
        {
            title: 'Price',
            key: 'price',
            dataIndex: 'price'
        },  
        {
            title: 'Total',
            key: 'total',
            dataIndex: 'total'
        },      
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            title: 'Order ID',
            key: 'orderID',
            dataIndex: 'orderID'
        }
    ];

    return <>
             <Table dataSource={orders} columns={columns} loading={loading} 
                    pagination={false}>
            </Table>
            <div className='paginationStyle'>
                    <Pagination onChange={onPageChange}
                            total={orderTotal}
                            current={currentPage}
                            pageSize={pageSize} />
                </div>
          </>
}

export default UserOrders;
