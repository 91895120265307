import { AUTH_USER } from '../action-types/auth'

const initialState = {
    userData: {},
}

export default function authReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                userData: action.payload
            }
        default:
            return state
    }

}
