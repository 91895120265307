import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Table, Button, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getExpenses, createExpense, editExpense } from "../../store/actions/expense";
import CreateExpense from "../../components/expense/createExpense";
import EditExpense from "../../components/expense/editExpense";
import moment from "moment";

const Expenses = ({ expenses, getExpenses, createExpense, editExpense, serviceBudget }) => {
    const [showCreatePopup, handleCreatePopup] = useState(false);
    const [showEditPopup, handleEditPopup] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [currentExpense, setCurrentExpense] = useState({});

    useEffect(() => {
        setLoading(true);
        getExpenses().then(() => setLoading(false));
    }, []);
    
    const close = () => {
        handleCreatePopup(!showCreatePopup);        
    }

    const closeEdit = () => {
        handleEditPopup(!showEditPopup);        
    }
    
    const columns = [,
        {
            title: 'Date',
            key: 'date',
            render: (_, expense) => moment(expense.created).format('MMMM Do YYYY hh:mm:ss a')
        },
        {
            title: 'Expense',
            key: 'expense',
            dataIndex: 'expense'
        },
        {
            title: 'Info',
            key: 'info',
            render: (_, expense) => <InfoCircleOutlined className='infoStyle' onClick={() => {
                                                                                handleEditPopup(!showCreatePopup);
                                                                                setCurrentExpense(expense)
                                                                            }
                                                                          } />
        }
    ];

    return <div>
        <p><strong>Service Budget</strong> - {serviceBudget} BTC</p> 
       <Table dataSource={expenses} columns={columns} loading={loading} 
              pagination={false}>
        </Table>
        <Button className="blockStyle" type="primary" onClick={() => handleCreatePopup(!showCreatePopup)}>Create</Button>
        <Modal title="Create Expense" visible={showCreatePopup} footer={null} onCancel={close} destroyOnClose={true}>
            <CreateExpense cancel={close} create={data => createExpense(data)} getExpenses={getExpenses}
                            serviceBudget={serviceBudget} />
        </Modal>
        <Modal title="Edit Expense" visible={showEditPopup} footer={null} onCancel={closeEdit} destroyOnClose={true}>
            <EditExpense cancel={closeEdit} edit={(data, id) => editExpense(data, id)} getExpenses={getExpenses}
                           existingExpense={currentExpense.expense} existingDescription={currentExpense.description}
                           serviceBudget={serviceBudget} id={currentExpense.id} />
        </Modal>        
    </div>
}

function mapStateToProps(state) {
    return {
        expenses: state.expense.expenses,
        serviceBudget: state.expense.serviceBudget
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getExpenses: () => dispatch(getExpenses()),
        createExpense: data => dispatch(createExpense(data)),
        editExpense: (data, id) => dispatch(editExpense(data, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);