import { connect } from 'react-redux';
import LoginComponent from '../../components/login/login.jsx';
import { login } from '../../store/actions/auth';

const Login = props => {
    const { login } = props;
    return <LoginComponent login={login} />
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {  
        login: data => dispatch(login(data)),      
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
