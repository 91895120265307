import api from '../../api';
import Cookies from 'js-cookie';
import { AUTH_USER } from '../action-types/auth'

export function login({ email, password }) {
    return async dispatch => {
        try {
            const response = await api.post('/api/auth/login', { email, password, isAdmin: true });

            Cookies.set('token', response.token);

            dispatch({
                type: AUTH_USER,
                payload: response,
            });

            return true;
        } catch (error) {
            return error.message;
        }
    }
}

export function logout() {
    return async dispatch => {
        try {
            await api.post('/api/auth/logout');

            Cookies.remove('token');

            dispatch({
                type: AUTH_USER,
                payload: {},
            });

            return true;
        } catch (error) {
            return false;
        }
    }
}

export function getMe() {
    return async dispatch => {
        try {
            const response = await api.get('/api/auth/get-me');
            
            dispatch({
                type: AUTH_USER,
                payload: response,
            });

            return true;
        } catch (error) {
            Cookies.remove('token');

            dispatch({
                type: AUTH_USER,
                payload: {},
            });
            return false;
        }
    }
}