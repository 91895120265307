import api from '../../api';
import { GET_SETTINGS } from '../action-types/setting';

export function getSettings() {
    return async dispatch => {
        try {
            const response = await api.get('/api/settings');

            dispatch({
                type: GET_SETTINGS,
                payload: response
            })

            return true;
        } catch (error) {
            return false;
        }
    }
}

export function editSetting({ key, value }) {
    return async () => {
        try {
            await api.post('/api/settings/edit', { key, value });
            return true;
        } catch (error) {
            return false;
        }
    }
}