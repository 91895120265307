import api from '../../api';
import { GET_GIFT_CARDS } from '../action-types/giftCard';

export function getGiftCards({ page, itemsPerPage, keyword, isNew }) {
    return async dispatch => {
        try {
            const response = await api.post('/api/gift-cards', { page, itemsPerPage, keyword, isNew });
            {
                const { totalGiftCardCount, giftCards, itemsPerPage, page, count } = response;

                dispatch({
                    type: GET_GIFT_CARDS,
                    payload: { giftCards, itemsPerPage, page, count, totalGiftCardCount },
                });
            }
            

            return true;
        } catch (error) {
            return false;
        }
    }
}