import api from '../../api';
import { GET_WITHDRAWS } from '../action-types/withdraw';

export function getWithdraws({ page, itemsPerPage, keyword = '' }) {
    return async dispatch => {
        try {
            const response = await api.get(`/api/ledger/withdraws?page=${page}&itemsPerPage=${itemsPerPage}&keyword=${keyword}`);
            
            dispatch({
                type: GET_WITHDRAWS,
                payload: response,
            });

            return true;
        } catch (error) {
            return false;
        }
    }
}

export function handleWithdraw({ ledgerId, withdrawStatus, rejectReason }) {
    return async dispatch => {
        try {
            await api.put(`/api/handle-withdraw-admin?ledgerId=${ledgerId}&withdrawStatus=${withdrawStatus}&rejectReason=${rejectReason}`);

            return true;
        } catch (error) {
            return error.message;
        }
    }
}

export function cancelWithdraw({ ledgerId }) {
    return async dispatch => {
        try {
            await api.put(`/api/cancel-withdraw-admin?ledgerId=${ledgerId}`);

            return true;
        } catch (error) {
            return error.message;
        }
    }
}

export function refund({ ledgerId }) {
    return async dispatch => {
        try {
            await api.put(`/api/refund?ledgerId=${ledgerId}`);

            return true;
        } catch (error) {
            return error.message;
        }
    }
}
