import { useState, useEffect } from 'react';
import { Table, Input, Button, Modal, message, Pagination } from 'antd';
import { connect } from 'react-redux';
import { getWithdraws, handleWithdraw, cancelWithdraw, refund } from '../../store/actions/withdraw';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from "moment";

const { Search } = Input;
const { confirm } = Modal;

const Withdraws = props => {
    const { withdraws, getWithdraws, withdrawCount, 
            handleWithdraw, totalWithdrawCount, cancelWithdraw, refund } = props;
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [showWithdrawPopup, setWithdrawPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [id, setId] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showRejectReasonPopup, setShowRejectReasonPopup] = useState(false);
    const pageSize = 10;

    useEffect(() => {
        setLoading(true);
        getWithdraws({ page: 1, itemsPerPage: pageSize }).then(() => setLoading(false))
    }, []);

    useEffect(() => {
        if (!showWithdrawPopup) {
            setId('');
            setEmail(''); 
            setType(''); 
            setRejectReason('');
        }
    }, [showWithdrawPopup])

    const onSearch = async value => {
        setKeyword(value);
        setCurrentPage(1);
        setLoading(true);
        await getWithdraws({ page: 1, itemsPerPage: pageSize, keyword: value });
        setLoading(false);
    }

    const handleWithdrawFunc = (id, email, type) => {
        setWithdrawPopup(!showWithdrawPopup); 
        setId(id);
        setEmail(email); 
        setType(type);        
    }

    const confirmWithdraw = () => {
        const data = { ledgerId: id, withdrawStatus: type === 'approve' ? 'approved' : 'rejected'};
        if (type === 'reject') {
            data.rejectReason = rejectReason;
        }
        handleWithdraw(data).then(res => {
            if (res === true) {
                message.success('Successfully updated');
                setWithdrawPopup(false); 
            } else {
                message.error(res);
                if (type === 'approve') {
                    setWithdrawPopup(false); 
                }
            }
            getWithdraws({ page: currentPage, itemsPerPage: pageSize, keyword });
        });   
    }

    const handleCancel = (id, email) => {
        confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <span>Do you want to cancel the withdraw request of user <strong>{email}</strong>?</span>,
            okText: 'Yes',
            onOk() {
                cancelWithdraw({ ledgerId: id}).then(res => {
                    if (res === true) {
                        message.success('Successfully cancelled');
                        getWithdraws({ page: currentPage, itemsPerPage: pageSize, keyword });
                    } else {
                        message.error(res);
                    }
                });                
            },
            onCancel() {},
          });
    }

    const handleRefund = (id, email) => {
        confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure to refund the user <strong>{email}</strong>?</span>,
            okText: 'Yes',
            onOk() {
                refund({ ledgerId: id}).then(res => {
                    if (res === true) {
                        message.success('Successfully refunded');
                        getWithdraws({ page: currentPage, itemsPerPage: pageSize, keyword });
                    } else {
                        message.error(res);
                    }
                });                
            },
            onCancel() {},
          });
    }

    const onPageChange = async value => {
        setCurrentPage(value);
        setLoading(true);
        await getWithdraws({ page: value, itemsPerPage: pageSize, keyword });
        setLoading(false);
    }

    const columns = [
        {
            title: 'Date',
            key: 'date',
            render: (_, record) => moment(record.created).format('MMMM Do YYYY hh:mm:ss a')
        },
        {
            title: 'User Email',
            key: 'email',
            render: (_, record) => record.user[0].email
        },
        {
            title: 'Amount',
            key: 'amount',
            render: (_, record) => -1 * record.paymentAmount
        },
        {
            title: 'Currency',
            key: 'currency',
            dataIndex: 'paymentCurrency'
        },
        {
            title: 'Withdraw status',
            key: 'withdrawStatus',
            render: (_, record) => {
                if (record.extra.status === 'pending') {
                    return <Button className="buttonStyle antdButtonThirdType" onClick={() => handleCancel(record._id, record.user[0].email)}>Cancel</Button>
                }
                if (record.extra.status === 'cancelled') {
                    return <>
                            <span style={{'color': 'rgba(6, 19, 34, 0.9)', 'fontSize': '17px'}}>
                                {record.extra.status}
                            </span>
                            <span style={{'marginLeft': '5px'}}>
                                {record.extra.handleDate ? `(${moment(record.extra.handleDate).format('MMMM Do YYYY hh:mm:ss a')})` : '(No Date)'}
                            </span>
                        </>;
                }
                if (record.extra.withdrawStatus) {
                    if (record.extra.withdrawStatus === 'failed') {
                        return <span style={{'color': 'black', 'fontSize': '17px'}}>
                                 {record.extra.withdrawStatus} 
                                 {record.extra.refunded ? ' - refunded' : <Button style={{'marginLeft': '5px'}} className="buttonStyle" onClick={() => handleRefund(record._id, record.user[0].email)}>Refund</Button>}
                               </span>
                    }
                    return <>
                            <span style={{'color': record.extra.withdrawStatus === 'rejected' ? 'red' : 'green', 'fontSize': '17px'}}>
                                {record.extra.withdrawStatus} {!!record.extra.rejectReason && <InfoCircleOutlined onClick={() => {
                                                                                                                        setShowRejectReasonPopup(true);
                                                                                                                        setRejectReason(record.extra.rejectReason);
                                                                                                                    }} /> }                                
                            </span>
                            <span style={{'marginLeft': '5px'}}>
                               {record.extra.handleDate ? `(${moment(record.extra.handleDate).format('MMMM Do YYYY hh:mm:ss a')})` : '(No Date)'}
                            </span>
                        </>;
                }
                return <div>
                            <Button type="danger" className="buttonStyle" onClick={() => handleWithdrawFunc(record._id, record.user[0].email, 'reject')}>Reject</Button>
                            <Button type="primary" className="buttonStyle" onClick={() => handleWithdrawFunc(record._id, record.user[0].email, 'approve')}>Approve</Button>
                       </div>
            }
        },
    ];

    return  <>
            <div>
                <h2>Withdraw requests - Total Count {totalWithdrawCount}</h2>
                <Search placeholder="Email" allowClear onSearch={onSearch} style={{ width: 270 }} />
                <Table dataSource={withdraws} columns={columns} loading={loading} pagination={false}></Table>                
                <Modal title="Handle withdraw" visible={showWithdrawPopup} footer={null} onCancel={() => setWithdrawPopup(false)} destroyOnClose={true}>
                    <div>
                        <div>Do you want to {type} the withdraw request of user <strong>{email}</strong>?</div>
                        <br />
                        {type === 'reject' && 
                        <div>
                            <Input className="inputStyle" addonBefore="Reason" type="text" value={rejectReason} onChange={e => setRejectReason(e.target.value)} />
                        </div>}
                        <div className="blockStyle">
                            <Button type="primary" className="buttonStyle" onClick={confirmWithdraw}>Yes</Button>
                            <Button type="danger" className="buttonStyle" onClick={() => setWithdrawPopup(false)}>Cancel</Button>
                        </div>                        
                    </div>
                </Modal>
            </div>
            <div className='paginationStyle'>
                <Pagination onChange={onPageChange}
                    total={withdrawCount}
                    current={currentPage}
                    pageSize={pageSize} />
            </div>
            <Modal title="Reject Reason" visible={showRejectReasonPopup} footer={null} onCancel={() => {
                                                                                            setShowRejectReasonPopup(false);
                                                                                            setRejectReason('');
                                                                                        }} destroyOnClose={true}>
                <p>{rejectReason}</p>
            </Modal>
            </>
}

function mapStateToProps(state) {
    return {
        withdraws: state.withdraw.withdraws.items,
        withdrawCount: state.withdraw.withdraws.total,
        totalWithdrawCount: state.withdraw.withdraws.totalWithdrawCount
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getWithdraws: data => dispatch(getWithdraws(data)),
        handleWithdraw: data => dispatch(handleWithdraw(data)),
        cancelWithdraw: data => dispatch(cancelWithdraw(data)),
        refund: data => dispatch(refund(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Withdraws);
