import { GET_ADJUSTMENTS } from '../action-types/adjustment';

const initialState = {
    adjustments: {items: []}
}

export default function adjustmentReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }
    
    switch (action.type) {
        case GET_ADJUSTMENTS:
            return {
                ...state,
                adjustments: action.payload
            }
        default:
            return state
    }

}
