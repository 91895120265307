import { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import classes from './login.module.scss';

const Login = props => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const login = async () => {
        if (email && password) {
            const res = await props.login({ email, password, isAdmin: true });
            if (res === true) {
                history.push('/');
            } else {
                message.error(res);
            }
        }        
    }

    return (
        <div className={classes.centerBlock}>
            <div className={classes.body}>
                <Form onFinish={login} layout="vertical">
                    <Form.Item label="Email" name="email"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input your email!',
                                    },
                                ]}>
                        <Input name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Password" name="password"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input your password!',
                                    },
                                ]}>
                        <Input.Password name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item style={{'textAlign': 'center'}}>
                        <Button type="primary" htmlType="submit">
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
    
}

export default Login;